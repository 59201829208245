import React, {useEffect, useState} from 'react';
import logo from "./images/logo.svg";
import labels from './labels';
import {ChartBarIcon, ClockIcon, TrophyIcon} from "./Constants";
import DateSelector from './DateSelector';
import FilterButton from "./FilterButton";
import {sortMatches} from "./Utility";
import FeatureGrid from "./FeatureGrid"; // Importa il componente DateSelector

const Homepage = () => {
    const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [sortCriteria, setSortCriteria] = useState(null); // Stato per il criterio di ordinamento
    const [isLoading, setIsLoading] = useState(true);

    const [matches, setMatches] = useState([]);
    const [language, setLanguage] = useState('en');
    const [currentLabels, setCurrentLabels] = useState(labels[language]);
    const [selectedDateId, setSelectedDateId] = useState(currentLabels.today);
    const [selectedDay, setSelectedDay] = useState(0); // Stato per selezione giorno
    const [matchesByDay, setMatchesByDay] = useState({}); // Memorizza i match per giorno

    useEffect(() => {
        // Recupera la lingua salvata o usa quella del browser
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setLanguage(initialLanguage);
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    useEffect(() => {
        // Aggiorna le etichette quando cambia la lingua salvata dal Footer
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && savedLanguage !== language) {
            setLanguage(savedLanguage);
            setCurrentLabels(labels[savedLanguage]);
        }
    }, [language]);

    useEffect(() => {
        // Aggiorna selectedDateId quando currentLabels cambia
        setSelectedDateId(currentLabels.today);
    }, [currentLabels]);

    useEffect(() => {
        if (matches.length > 0) {
            setIsLoading(false);
        }
    }, [matches]);


    useEffect(() => {
        const fetchMatches = async (day) => {
            setMatches([]);
            setIsLoading(true)
            try {
                const response = await fetch(`/api/matches?day=${day}`);
                const data = await response.json();
                setMatchesByDay(prevState => ({
                    ...prevState,
                    [day]: data, // Salva i dati per il giorno specifico
                }));
                if (selectedDay === day) {
                    setMatches(data); // Mostra i match per il giorno selezionato
                }
            } catch (error) {
                console.error('Error fetching daily matches:', error);
            }
        };

        // Carica i match solo se non sono già stati caricati per il giorno selezionato
        if (!matchesByDay[selectedDay]) {
            fetchMatches(selectedDay);
        } else {
            setMatches(matchesByDay[selectedDay]); // Usa i dati già caricati
        }
    }, [selectedDay, matchesByDay]);

    const handleMatchClick = (matches, match) => {
        if (match.isPremium === 1) {
            setOpenPurchaseDialog(true);
        } else {
            if(!sortCriteria){
                localStorage.setItem("matches", JSON.stringify(matches.filter(match => match.hasOwnProperty('averages'))));
            }
            localStorage.setItem("match", JSON.stringify(match));
            // Redirige alla pagina desiderata
            window.location.href = "/match";
        }
    };

    const handleClosePurchaseDialog = () => {
        setOpenPurchaseDialog(false); // Chiudi il popup
    };

    const handleSort = (criteria) => {
        if (criteria === 'default' || criteria === 'alphabetical') {
            setSortCriteria(criteria);
        } else {
            setOpenPurchaseDialog(true)
        }
    };

    return (
        <div className="bg-gray-50 min-h-screen text-gray-900">
            {/* Gradient Header */}
            <div className="bg-gradient-to-r from-blue-600 to-purple-700 text-white">
                <div className="container mx-auto px-4 py-16 text-center">
                    {/* Logo */}
                    <div className="mb-4">
                        <img
                            src={logo}
                            alt="GizzoBet Logo"
                            className="w-[20rem] h-auto mx-auto filter invert" // Logo aumentato del 30% in termini di larghezza
                        />
                    </div>
                    <p className="text-xl max-w-2xl mx-auto text-blue-100">
                        {currentLabels.homePageTitle}
                    </p>
                    {/* Add login and register buttons */}
                    <div className="mt-6">
                        <a href="/login">
                            <button
                                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-semibold">
                                {currentLabels.login}
                            </button>
                        </a>
                        <a href="/register">
                            <button
                                className="ml-4 px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 font-semibold">
                                {currentLabels.register}
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            {/* Matches Section */}
            <div className="container mx-auto px-4 py-12">
                <h2 className="text-3xl font-bold mb-8 text-center">
                    {selectedDateId + currentLabels.label_predictions}
                </h2>

                <div className="flex flex-col">
                    <div className="container mx-auto px-4 py-4 flex flex-wrap items-center">
                        <DateSelector
                            className="mr-2"
                            onSelect={(id, day) => {
                                setSelectedDateId(day);
                                setSelectedDay(id);
                            }}
                            currentLabels={currentLabels}
                        />
                        <div className="flex-grow flex justify-center mt-2 md:mt-0 md:justify-end">
                            <FilterButton onSort={handleSort} currentLabels={currentLabels} isPremium={false}/>
                        </div>
                    </div>
                </div>

                <div className="bg-white shadow-lg rounded-xl overflow-hidden">
                    {isLoading ? (
                        <div className="flex justify-center items-center py-12">
                            <div
                                className="animate-spin rounded-full h-10 w-10 border-t-2 border-blue-500 border-solid"></div>
                        </div>
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className="bg-gray-100 border-b">
                                <tr>
                                    <th className="p-4 text-left text-gray-600">{currentLabels.match}</th>
                                    <th className="p-4 text-left text-gray-600">{currentLabels.league}</th>
                                    <th className="p-4 text-left text-gray-600">{currentLabels.time}</th>
                                    <th className="p-4 text-left text-gray-600">{currentLabels.status}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(() => {
                                    const sortedMatches = sortMatches(
                                        sortCriteria,
                                        matches
                                            .filter((match) => match.isPremium === 0)
                                            .concat(matches.filter((match) => match.isPremium === 1))
                                    );

                                    localStorage.setItem(
                                        "matches",
                                        JSON.stringify(sortedMatches.filter(match => match.hasOwnProperty('averages')))
                                    );

                                    return sortedMatches.map((match) => (
                                        <tr
                                            key={match.id}
                                            className="hover:bg-gray-50 cursor-pointer border-b last:border-b-0"
                                            onClick={() => handleMatchClick(matches, match)}
                                        >
                                            <td className="p-4 font-semibold">{match.homeTeam} vs {match.awayTeam}</td>
                                            <td className="p-4 text-gray-600">{match.league}</td>
                                            <td className="p-4 text-gray-600">
                                                {new Date(
                                                    new Date(match.eventTime).setHours(
                                                        new Date(match.eventTime).getHours() + 1
                                                    )
                                                ).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                                            </td>
                                            <td className="p-4">
                                                {match.isPremium === 0 ? (
                                                    <span
                                                        className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs font-semibold">
                                                        {currentLabels.free}
                                                    </span>
                                                ) : (
                                                    <span
                                                        className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full text-xs font-semibold">
                                                        {currentLabels.premium}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    ));
                                })()}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>

            <FeatureGrid labels={currentLabels}/>

            {/* Purchase Dialog */}
            {openPurchaseDialog && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-2xl shadow-2xl p-8 w-[500px] text-center">
                        <h2 className="text-3xl font-bold mb-4 text-blue-800">{currentLabels.upgrade_to_pro_title}</h2>
                        <p className="text-gray-600 mb-6 text-lg">{currentLabels.upgrade_to_pro_description}</p>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="px-6 py-3 bg-gray-200 rounded-lg hover:bg-gray-300 font-semibold"
                                onClick={handleClosePurchaseDialog}
                            >
                                {currentLabels.cancel}
                            </button>
                            <button
                                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-semibold"
                                onClick={() => window.location.href = '/register'}
                            >
                                {currentLabels.view_pro_plans}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Homepage;
