import {ChartBarIcon, ClockIcon, TrophyIcon} from "./Constants";

const FeatureGrid = ({ labels }) => {
    const features = [
        {
            icon: <TrophyIcon />,
            title: labels.banner1,
            description: labels.banner1description
        },
        {
            icon: <ChartBarIcon />,
            title: labels.banner2,
            description: labels.banner2description
        },
        {
            icon: <ClockIcon />,
            title: labels.banner3,
            description: labels.banner3description
        }
    ];

    return (
        <div className="container mx-auto px-4 py-2">
            <div className="grid md:grid-cols-3 gap-8">
                {features.map((feature, index) => (
                    <div
                        key={index}
                        className="bg-white shadow-lg rounded-xl p-6 text-center hover:shadow-xl transition-all"
                    >
                        <div className="flex justify-center mb-4">{feature.icon}</div>
                        <h2 className="text-xl font-bold mb-3 text-gray-800">{feature.title}</h2>
                        <p className="text-gray-600">{feature.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeatureGrid;
