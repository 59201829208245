import React, { useState } from 'react';

const DateSelector = ({ onSelect, currentLabels }) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const dayAfter = new Date(today);
    dayAfter.setDate(dayAfter.getDate() + 2);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}/${month}`;
    };

    const getDayName = (date) => {
        if (currentLabels.lang === 'en') {
            return date.toLocaleDateString('en-US', { weekday: 'long' });
        }
        if (currentLabels.lang === 'ro') {
            return date.toLocaleDateString('ro-RO', { weekday: 'long' });
        }
    };

    const [selectedId, setSelectedId] = useState(0);

    const dateOptions = [
        { id: 0, label: currentLabels.today, day: getDayName(today) },
        { id: 1, label: currentLabels.tomorrow, day: getDayName(tomorrow) },
        { id: 2, label: `${formatDate(dayAfter)}`, day: getDayName(dayAfter) }
    ];

    const handleSelect = (option) => {
        setSelectedId(option.id);
        onSelect?.(option.id, option.label);
    };

    // Stili in linea
    const buttonStyle = {
        height: 'auto',
        padding: '0.5rem 1rem',
        fontSize: '0.875rem',
        fontWeight: '500',
        transition: 'background-color 0.2s, color 0.2s',
        whiteSpace: 'normal', // Permette al testo di andare a capo
        wordWrap: 'break-word', // Permette di spezzare parole lunghe
    };

    return (
        <div className="inline-flex items-center divide-x divide-gray-100 bg-white rounded-lg shadow-sm border border-gray-200">
            {dateOptions.map((option) => (
                <button
                    key={option.id}
                    onClick={() => handleSelect(option)}
                    style={{
                        ...buttonStyle,
                        backgroundColor: selectedId === option.id ? '#e0f2ff' : 'transparent',
                        color: selectedId === option.id ? '#4f46e5' : '#4b5563',
                    }}
                    className={`first:rounded-l-lg last:rounded-r-lg`}
                >
                    {option.label} ({option.day})
                </button>
            ))}
        </div>
    );
};

export default DateSelector;