import React, { useEffect, useRef, useState } from "react";
import labels from "./labels";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactDOM from 'react-dom/client';
import {
    Avatar,
    Box,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import {
    convertStatsKeys,
    convertToCents,
    findMatchPosition,
    generateImageCanvas,
    getTeamLogo,
    truncateDecimal
} from './Utility';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useNavigate} from "react-router-dom";

const MatchSelector = () => {
    const [teamData, setTeamData] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState("");
    const [selectedTeam1, setSelectedTeam1] = useState("");
    const [selectedTeam2, setSelectedTeam2] = useState("");
    const [matches, setMatches] = useState(null);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);


    const [showSelector, setShowSelector] = useState(true);
    const [showStats, setShowStats] = useState(false);
    const [isQuery, setIsQuery] = useState(false);
    const [error, setError] = useState(null);
    const [matchStats, setMatchStats] = useState(null);
    const [centMode, setCentMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const fetchDone = useRef(false);

    const [language, setLanguage] = useState('en');
    const [currentLabels, setCurrentLabels] = useState(labels[language]);

    useEffect(() => {
        // Recupera la lingua salvata o usa quella del browser
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setLanguage(initialLanguage);
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    useEffect(() => {
        // Aggiorna le etichette quando cambia la lingua salvata dal Footer
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && savedLanguage !== language) {
            setLanguage(savedLanguage);
            setCurrentLabels(labels[savedLanguage]);
        }
    }, [language]);

    useEffect(() => {

        const browserLanguage = navigator.language.startsWith("ro") ? "ro" : "en";
        setLanguage(browserLanguage);

        const headerMatch = localStorage.getItem("match");
        const headerMatches = localStorage.getItem("matches");

        const parsedMatch = headerMatch ? JSON.parse(headerMatch) : null;
        const parsedMatches = headerMatches ? JSON.parse(headerMatches) : null;
        setMatches(parsedMatch)
        const matchPosition = findMatchPosition(parsedMatches, parsedMatch);
        setCurrentMatchIndex(matchPosition)
        const fetchTeamData = async () => {
            try {
                const response = await fetch("/api/config");
                const data = await response.json();
                setTeamData(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchTeamData();

        if (parsedMatch.homeTeam && parsedMatch.awayTeam) {
            setSelectedLeague(parsedMatch.league);
            setSelectedTeam1(parsedMatch.homeTeam);
            setSelectedTeam2(parsedMatch.awayTeam);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setIsQuery(true);
            setMatchStats(parsedMatch.averages);
            setShowStats(true);
            setShowSelector(false);
        }


    }, []);


    const goToPreviousMatch = () => {
        if (currentMatchIndex > 0) {
            setCurrentMatchIndex(currentMatchIndex - 1);
            const matches = localStorage.getItem("matches");
            const parsedMatches = matches ? JSON.parse(matches) : null;
            const parsedMatch = parsedMatches[currentMatchIndex - 1];
            setSelectedLeague(parsedMatch.league);
            setSelectedTeam1(parsedMatch.homeTeam);
            setSelectedTeam2(parsedMatch.awayTeam);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setIsQuery(true);
            setMatchStats(parsedMatch.averages);
            setShowStats(true);
            setShowSelector(false);
        }
    };

    const goToNextMatch = () => {
        const matches = localStorage.getItem("matches");
        const parsedMatches = matches ? JSON.parse(matches) : null;
        if (currentMatchIndex < parsedMatches.length - 1) {
            setCurrentMatchIndex(currentMatchIndex + 1);
            const parsedMatch = parsedMatches[currentMatchIndex + 1];
            setSelectedLeague(parsedMatch.league);
            setSelectedTeam1(parsedMatch.homeTeam);
            setSelectedTeam2(parsedMatch.awayTeam);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setIsQuery(true);
            setMatchStats(parsedMatch.averages);
            setShowStats(true);
            setShowSelector(false);
        }
    };

    const statsRef = useRef(null);

    return (
        <Container maxWidth="md">
            {matchStats && (
                <Box>

                        <Box sx={{ width: "100%", textAlign: "left", marginBottom: 2, marginTop: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                component="a"
                                href="/"
                                sx={{
                                    borderRadius: "12px",
                                    padding: "8px 20px",
                                    backgroundColor: "#1976d2", // Primary blue color
                                    "&:hover": {
                                        backgroundColor: "#1565c0", // Darker blue on hover
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: 3,
                                }}
                            >
                                <ArrowBackIcon sx={{ color: "white", marginRight: 1 }} />
                                {currentLabels.back}
                            </Button>
                        </Box>


                    <Box textAlign="center" mt={2} sx={{ mx: "auto", width: { xs: "90%", sm: "70%" }, marginTop: 4 }}>
                        <Grid container spacing={2} alignItems="center" justifyContent="center" wrap="nowrap">
                            <Grid item sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>1</Typography>
                                <Typography>{truncateDecimal(matchStats.generalOdds[0].A, 1)}%</Typography>
                            </Grid>
                            <Grid item sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>X</Typography>
                                <Typography>{truncateDecimal(matchStats.generalOdds[0].X, 1)}%</Typography>
                            </Grid>
                            <Grid item sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>2</Typography>
                                <Typography>{truncateDecimal(matchStats.generalOdds[0].B, 1)}%</Typography>
                            </Grid>

                            <Grid item sx={{ textAlign: "center", ml: 4 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>GG</Typography>
                                <Typography>{truncateDecimal(matchStats.generalOdds[0].GG, 1)}%</Typography>
                            </Grid>
                            <Grid item sx={{ textAlign: "center", ml: 4 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>OV (2.5)</Typography>
                                <Typography>{truncateDecimal(matchStats.generalOdds[0].OV, 1)}%</Typography>
                            </Grid>
                        </Grid>
                        <br />
                    </Box>

                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} sm={5}>
                            <Typography variant="h6" sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%"
                            }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Avatar
                                        src={getTeamLogo(selectedTeam1, teamData)}
                                        alt={selectedTeam1}
                                        variant="square"
                                        sx={{ width: 35, height: 35, marginRight: 1 }}
                                    />
                                    <Typography variant="h9" sx={{ fontWeight: 'bold' }}>
                                        {selectedTeam1}
                                    </Typography>
                                </Box>
                            </Typography>
                            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={2}>{currentLabels.statistics}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(convertStatsKeys(matchStats.homeAverages, currentLabels.statsMap)[0]).map(([stat, value]) => (
                                            <TableRow key={stat}>
                                                <TableCell>{stat}</TableCell>
                                                <TableCell align="right">{convertToCents(value)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        {/* Frecce di navigazione */}
                        <Grid item xs={12} sm={2} sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <IconButton onClick={goToPreviousMatch} sx={{ fontSize: 40 }} disabled={currentMatchIndex === 0}>
                                    <ArrowBackIosIcon />
                                </IconButton>
                                <IconButton onClick={goToNextMatch} sx={{ fontSize: 40 }} disabled={currentMatchIndex === JSON.parse(localStorage.getItem("matches")).length - 1}>
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </Box>
                        </Grid>


                        <Grid item xs={12} sm={5}>
                            <Typography variant="h6" sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%"
                            }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Avatar
                                        src={getTeamLogo(selectedTeam2, teamData)}
                                        alt={selectedTeam2}
                                        variant="square"
                                        sx={{ width: 35, height: 35, marginRight: 1 }}
                                    />
                                    <Typography variant="h9" sx={{ fontWeight: 'bold' }}>
                                        {selectedTeam2}
                                    </Typography>
                                </Box>
                            </Typography>
                            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={2}>{currentLabels.statistics}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(convertStatsKeys(matchStats.awayAverages, currentLabels.statsMap)[0]).map(([stat, value]) => (
                                            <TableRow key={stat}>
                                                <TableCell>{stat}</TableCell>
                                                <TableCell align="right">{convertToCents(value)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    {/* Frecce di navigazione per desktop */}
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center', marginTop: 2 }}>
                        <IconButton onClick={goToPreviousMatch} sx={{ fontSize: 40 }} disabled={currentMatchIndex === 0}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <IconButton onClick={goToNextMatch} sx={{ fontSize: 40 }} disabled={currentMatchIndex === JSON.parse(localStorage.getItem("matches")).length - 1}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default MatchSelector;