import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

const FilterButton = ({ onSort, currentLabels, isPremium }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const dropdownRef = useRef(null);

    let sortOptions = [
        { id: 'default', label: "Default" },
        { id: 'alphabetical', label: currentLabels.alphabetical },
        { id: 'gg', label: 'GG' },
        { id: 'ov', label: 'OV' },
        { id: '1', label: '1' },
        { id: 'x', label: 'X' },
        { id: '2', label: '2' }
    ];

    const handleSelect = (option) => {
        setSelectedOption(option);
        onSort(option.id);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className="relative inline-block text-left" ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex items-center justify-center gap-2 h-9 px-4 text-sm font-medium bg-white border border-gray-200 rounded-lg shadow-sm transition-all duration-200 hover:bg-gray-50"
            >
                <span className={`${selectedOption ? 'text-gray-900' : 'text-gray-600'}`}>
                    {selectedOption && !isPremium && selectedOption.id !== 'alphabetical'
                        ? currentLabels.alphabetical
                        : (selectedOption ? selectedOption.label : currentLabels.sortBy)}
                </span>
                <ChevronDown className={`w-4 h-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
            </button>

            {isOpen && (
                <div className="absolute top-full left-0 mt-1 bg-white rounded-lg shadow-lg border border-gray-100 w-56 z-50 overflow-hidden">
                    {sortOptions.map((option) => (
                        <button
                            key={option.id}
                            onClick={() => handleSelect(option)}
                            className={`
                                h-10 px-3 text-sm font-medium transition-colors duration-200 w-full  
                                ${selectedOption?.id === option.id
                                ? 'bg-indigo-50 text-indigo-600'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                            }
                                rounded-none first:rounded-t-lg last:rounded-b-lg  
                            `}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FilterButton;
